/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {

      },
      finalize: function () {

        jQuery('.widget-search-apartments-main-menu').on('click', function () {
          jQuery('#widget-btn').click();
        });

        // ScrollTo
        $('a[href*="#"]:not([data-toggle])').click(function () {
          if ($(this).attr("href") == "#carouselIndicators") return;//This is the exception
          if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            || location.hostname == this.hostname) {

            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
              $('html,body').animate({
                scrollTop: target.offset().top - $('header.banner').outerHeight()
              }, 500);
              return false;
            }
          }
        });

        // Filter for objects list
        function filterCategory(value, target) {
          var tempArray = $(target);
          if (value == '-1') {
            tempArray.each(function () {
              $(this).css({'display': 'block'});
            });
          } else {
            tempArray.each(function () {
              if ($(this).attr('data-filter-value') == value) {
                $(this).css({'display': 'block'});
              } else {
                $(this).css({'display': 'none'});
              }
            });
          }
        }

        if ($('.tab-nav').length > 0) {
          $('.tab-nav>.nav-item').on('tap click', function (e) {
            e.preventDefault();
            $('.nav-item').removeClass('active');
            $(this).addClass('active');
            filterCategory($(this).data('filter'), $(this).data('target'));
          });
        }

        // Modal
        // var modal = $('#myModal');
        // if (sessionStorage.getItem('disclaimer_agreement') !== 'user_agree') {
        //    modal.modal('show');
        // }
        //
        // $('#yes-disclaimer-button').click(function () {
        //    sessionStorage.setItem('disclaimer_agreement', 'user_agree');
        // });
        // $('#myModal .close').click(function () {
        //    sessionStorage.setItem('disclaimer_agreement', 'user_agree');
        // });
        // $(document).mouseup(function (e){
        //     var div = $("#myModal .modal-dialog");
        //     if (!div.is(e.target)
        //         && div.has(e.target).length === 0) {
        //         sessionStorage.setItem('disclaimer_agreement', 'user_agree');
        //     }
        // });
        //
        // $('#yes-disclaimer-button-link').click(function () {
        //    sessionStorage.setItem('disclaimer_agreement', 'user_agree');
        //    location.href = $(this).attr('href');
        // });
        // End modal

        function new_map($el) {
          var $markers = $el.find('.marker');
          var args = {
            zoom: 16,
            scrollwheel: false,
            mapTypeControl: false,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP
          };

          // create map
          var map = new google.maps.Map($el[0], args);

          // add a markers reference
          map.markers = [];

          // add markers
          $markers.each(function () {
            add_marker($(this), map);
          });

          // center map
          center_map(map);
          return map;
        }

        var icon;

        function add_marker($marker, map) {
          var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

          if ($marker.attr('data-icon') == 'main' || $marker.attr('data-icon') == 'violet') {
            icon = '/wp-content/themes/vambud/assets/images/icon1.png';
          } else if ($marker.attr('data-icon') == 'grey') {
            icon = '/wp-content/themes/vambud/assets/images/location_old.png';
          } else if ($marker.attr('data-icon') == 'pink') {
            icon = '/wp-content/themes/vambud/assets/images/location_new.png';
          } else {
            icon = '/wp-content/themes/vambud/assets/images/icon2.png';
          }
          //icon = (($marker.attr('data-icon') == 'main') ? '/wp-content/themes/vambud/assets/images/icon1.png' : '/wp-content/themes/vambud/assets/images/icon2.png');
          // create marker
          var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: icon,
            animation: google.maps.Animation.DROP
          });

          // add to array
          map.markers.push(marker);

          // if marker contains HTML, add it to an infoWindow
          if ($marker.html()) {
            // create info window
            var infowindow = new google.maps.InfoWindow({
              content: $marker.html()
            });

            //infowindow.open(map, marker);

            if ($marker.attr('data-icon') == 'main') {
              infowindow.open(map, marker);
            } else {
              // show info window when marker is clicked
              google.maps.event.addListener(marker, 'click', function () {
                infowindow.open(map, marker);
              });
              google.maps.event.addListener(marker, 'mouseover', function () {
                infowindow.open(map, marker);
              });
              google.maps.event.addListener(marker, 'mouseout', function () {
                infowindow.close(map, marker);
              });
            }
          }
        }

        function center_map(map) {
          // vars
          var bounds = new google.maps.LatLngBounds();
          // loop through all markers and create bounds
          $.each(map.markers, function (i, marker) {
            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
            bounds.extend(latlng);

          });

          // only 1 marker?
          if (map.markers.length == 1) {
            // set center of map
            map.setCenter(bounds.getCenter());
            map.setZoom(16);
          }
          else {
            map.fitBounds(bounds);
          }

        }

        var map = null;
        jQuery(document).ready(function () {
          $('.acf-map').each(function () {
            // create map
            map = new_map($(this));
          });
        });


        // Gallery
        var gallery = $(".gallery");

        gallery.justifiedGallery({
          rowHeight: 150,
          margins: 15,
          lastRow: 'nojustify'
        });

        gallery.magnificPopup({
          delegate: 'a',
          type: 'image',
          gallery: {
            enabled: true
          }
        });

        $('.popup-youtube').magnificPopup({
          type: 'iframe'
        });


        jQuery(document).ready(function () {

          var body = $('body');
          var header = $('header.banner');


          // $('.fullpage>.page-content').fullpage({
          //   verticalCentered: false,
          //   responsiveWidth: 1200,
          //   responsiveHeight: 750,
          //   scrollBar: false,
          //   navigation: true,
          //   navigationPosition: 'right',
          //   slideSelector: '.fp-slider',
          //   onLeave: function (index, nextIndex, direction) {
          //     if (index === 1 && nextIndex === 2) {
          //       header.addClass('trnsp-grey');
          //     }
          //     if (nextIndex === 1) {
          //       header.removeClass('trnsp-grey');
          //     }
          //   },
          //   afterLoad: function (anchorLink, index) {
          //     if (index !== 1) {
          //       header.addClass('trnsp-grey');
          //     }
          //   }
          // });

          $(window).scroll(function () {
            if (header.offset().top > 0) {
              header.addClass('trnsp-grey');
            } else {
              header.removeClass('trnsp-grey');
            }
          });


          $('.owl-home-objects').owlCarousel({
            dots: false,
            nav: true,
            navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
            margin: 10,
            responsive: {
              0: {
                items: 1
              },
              767: {
                items: 2,
                margin: 10
              },
              991: {
                items: 3,
                margin: 10
              },
              1200: {
                items: 4,
                margin: 15
              }
            }
          });


          var button_container = $('.upload_cv');
          var input = $(button_container).find('input');
          $(input).on('change', function (e) {
            var fileName = e.target.value.split('\\').pop();
            $(button_container).find('.button-value').html(fileName);
            $(button_container).find('form').submit();
          });


          // About us
          $(".owl-achievement").owlCarousel({
            items: 1,
            dots: false,
            loop: true,
            nav: true,
            navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>']
          });

          $('.owl-vacancies').owlCarousel({
            dots: false,
            nav: true,
            navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
            margin: 30,
            responsive: {
              0: {
                items: 1
              },
              767: {
                items: 2,
                margin: 10
              },
              991: {
                items: 3,
                margin: 15
              },
              1200: {
                items: 3,
                margin: 30
              }
            }
          });
          $('.owl-page-estate-1, .owl-page-estate-2').owlCarousel({
            dots: false,
            nav: true,
            navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
            margin: 30,
            responsive: {
              0: {
                items: 1,
                margin: 0
              },
              767: {
                items: 2,
                margin: 10
              },
              991: {
                items: 3,
                margin: 15
              },
              1200: {
                items: 3,
                margin: 30
              }
            }
          });
          $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            e.target // newly activated tab
            e.relatedTarget // previous active tab
            $(".owl-carousel").trigger('refresh.owl.carousel');
          });


          // jQuery.fn.extend({
          //   toggleOwl: function(selector, options, destroy){
          //     return this.each(function(){
          //       $(this).find(selector).filter(function(){
          //         return $(this).parent().is(':visible');
          //       }).owlCarousel(options);

          //       $(this).on('shown.bs.tab', function(event){
          //         var target = $(event.target.getAttribute('href')).find(selector);
          //         if(!target.data('owlCarousel')){
          //           var owl = target.owlCarousel(options).data("owlCarousel");
          //         }
          //       });
          //       if(destroy === true){
          //         $(this).on('hide.bs.tab', function(event){
          //           var target = $(event.target.getAttribute('href')).find(selector);
          //           if(target.data('owl.carousel')){
          //             target.data('owl.carousel').destroy();
          //           }
          //         });
          //       }
          //     });
          //   }
          // });

          // jQuery(function($) {
          //   $('.toggleOwl').toggleOwl(' #pills-11 .owl-carousel', {
          //     loop: true,
          //     items: 5,
          //     margin: 25,
          //     nav: false,
          //     touchDrag: false
          //   });

          //   $('.toggleOwl').toggleOwl('.owl-carousel.style2', {
          //     loop: true,
          //     items: 8,
          //     margin: 1,
          //     nav: false,
          //     touchDrag: false
          //   });
          // });


        });

      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },


    'post_type_archive_object': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {

        // var mixer = mixitup('.objects-wrap .row', {
        //   animation: {
        //     duration: 300
        //   },
        //   selectors: {
        //     control: '.controls button'
        //   }
        // });
      }
    },

    'page_template_template_about': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        jQuery(document).ready(function () {
          $('#for-slick').slick({
            // slide: '.slick-slide',
            prevArrow: '<div class="prev-arrow"><i class="fa fa-angle-left"></i></div>',
            nextArrow: '<div class="next-arrow"><i class="fa fa-angle-right"></i></div>',
            dots: true,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            rows: 2,
            // responsive: [
            //   {
            //     breakpoint: 1300,
            //     settings: {
            //       slidesToShow: 3
            //     }
            //   },
            //   {
            //     breakpoint: 760,
            //     settings: {
            //       slidesToShow: 2
            //     }
            //   },
            //   {
            //     breakpoint: 560,
            //     settings: {
            //       slidesToShow: 1,
            //       slidesToScroll: 1
            //     }
            //   }
            //   // You can unslick at a given breakpoint now by adding:
            //   // settings: "unslick"
            //   // instead of a settings object
            // ]
          });
        });
      }
    },

    'page_template_template_estate': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {

        // import {WOW} from 'wow.js';
        // new WOW().init();
        AOS.init();

        $(".es-btn").click(function () {
          var offset = -80; //Offset of 20px

          $('html, body').animate({
            scrollTop: $("#apartment-search-wrap").offset().top + offset
          }, 1000);
        });

        var imarker = "/wp-content/themes/vambud/assets/images/icon1.png";
        var imarker2 = "/wp-content/themes/vambud/assets/images/location_new.png"
        // Our markers


        var gmarkers1 = [];
        var markers1 = [];
        markers1 = [
          ['0', 'Вамбуд Офіс', 48.931316, 24.725785, '1', imarker],
          ['1', 'ЖК ”КВАРТАЛ КРАКІВСЬКИЙ”', 48.947093, 24.734949, '1', imarker2],
          ['2', 'ЖК ”КВАРТАЛ ВІДЕНСЬКИЙ” ', 48.907561, 24.682674, 'vin', imarker2],
          ['3', 'ЖК “СОНЯЧНИЙ” ', 48.905070, 24.682820, 'vin', imarker2],
          ['4', 'ЖК ”ГАЛИЦЬКА АКАДЕМІЯ” ', 48.941686, 24.742848, 'vin', imarker2]

        ];

        /**
         * Function to init map
         */

        function initialize3() {
          directionsDisplay = new google.maps.DirectionsRenderer();
          var center3 = new google.maps.LatLng(48.921196, 24.709616);
          var mapOptions3 = {
            zoom: 13,
            center: center3,
            // zoomControl: false,
            disableDoubleClickZoom: true,
            scrollwheel: false,
            icon: imarker,
            mapTypeId: google.maps.MapTypeId.ROADMAP
          }

          map3 = new google.maps.Map(document.getElementById('map-2'), mapOptions3);
          directionsDisplay.setMap(map3);
          for (i = 0; i < markers1.length; i++) {
            addMarker(markers1[i]);
          }

        }

        /**
         * Function to add marker to map
         */

        function addMarker(marker) {
          var title = marker[1];
          var category = marker[4];
          var point = marker[5];
          var pos = new google.maps.LatLng(marker[2], marker[3]);

          marker1 = new google.maps.Marker({
            position: pos,
            map: map3,
            category: category,
            icon: point
          });

          gmarkers1.push(marker1);

          var infowindow = new google.maps.InfoWindow();

          var content = '<div class="map-adress">' + title + '</div>';

          google.maps.event.addListener(marker1, "click", (function (marker1) {
            return function (evt) {
              // var content = marker1.getTitle();
              infowindow.setContent(content);
              infowindow.open(map3, marker1);
            }
          })(marker1));

          // google.maps.event.addListener(marker1, "mouseout", (function(marker1) {
          //   return function(evt) {
          //     infowindow.close();
          //   }
          // })(marker1));

          infowindow.setContent(content);
          infowindow.open(map3, marker1);

        }


        /**
         * Function to filter markers by category
         */

        filterMarkers = function (category) {
          for (i = 0; i < markers1.length; i++) {
            marker = gmarkers1[i];
            // If is same category or category not picked
            if (marker.category == category || category.length === 0) {
              // marker.setVisible(true);
              map3.setZoom(15);
              map3.setCenter(marker.getPosition());
            }
          }
        }
        var directionsService = new google.maps.DirectionsService();


        google.maps.event.addDomListener(window, 'load', initialize3);

      }
    },

    'single_object': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        $('article').addClass('swiper-slide');

        // if ($('.object-apartments article').length > 0) {
        //     var mixer = mixitup('.object-apartments .text', {
        //         animation: {
        //             duration: 0
        //         },
        //         load: {
        //             filter: '.r0'
        //         },
        //         selectors: {
        //             control: '.controls button'
        //         },
        //         controls: {
        //             scope: 'local'
        //         }
        //     });
        // }

        // jQuery(document).ready( function () {

        // function sliderArgs(){
        //     var swiper = new Swiper('.swiper-container', {
        //         navigation: {
        //             nextEl: '.swiper-next',
        //             prevEl: '.swiper-prev'
        //         },
        //         slidesPerView: 5,
        //         slidesPerColumn: 2,
        //         mousewheelControl: false,
        //         //paginationClickable: true,
        //         spaceBetween: 20,
        //         watchSlidesVisibility: true,
        //         calculateHeight: true,
        //         //slidesOffsetBefore: 5,
        //         breakpoints: {
        //             575: {
        //                 slidesPerView: 1
        //             },
        //             767: {
        //                 slidesPerView: 2
        //             },
        //             990: {
        //                 slidesPerView: 2
        //             },
        //             1199: {
        //                 slidesPerView: 3
        //             },
        //             1400: {
        //                 slidesPerView: 4,
        //                 slidesPerColumn: 2,
        //                 spaceBetween: 20
        //             },
        //             1550: {
        //                 slidesPerView: 4,
        //                 slidesPerColumn: 2,
        //                 spaceBetween: 26
        //             }
        //         }
        //     });
        // }

        //var sliderArgs =  {
        //    infinite: false,
        //    arrows: true,
        //    rows: 2,
        //    slidesPerRow: 1,
        //    slidesToShow: 5,
        //    slidesToScroll: 1,
        //    responsive: [
        //        {
        //            breakpoint: 1700,
        //            settings: {
        //                rows: 2,
        //                slidesPerRow: 1,
        //                slidesToShow: 4
        //            }
        //        },
        //        {
        //            breakpoint: 1200,
        //            settings: {
        //                rows: 2,
        //                slidesPerRow: 1,
        //                slidesToShow: 3
        //            }
        //        },
        //        {
        //            breakpoint: 991,
        //            settings: {
        //                rows: 2,
        //                slidesPerRow: 1,
        //                slidesToShow: 2
        //            }
        //        },
        //        {
        //            breakpoint: 600,
        //            settings: {
        //                rows: 1,
        //                slidesToShow: 1
        //            }
        //        }
        //    ]
        //};

        //function initSlider(){
        //    $('.swiper-wrapper').slick(sliderArgs);
        //    console.log('init');
        //}

        //function reInitSlider(){
        //    $('.swiper-wrapper').slick('unslick');
        //    console.log('delete');
        //    $('.slick-slide').remove(); /* Remove current slides elements, in case that you want to show new slides. */
        //    $('.swiper-wrapper').slick(sliderArgs);
        //    console.log('init');
        //}

        // var slider_container = $('.swiper-wrapper');
        //
        // var r0 = $('article.r0');
        // var r1 = $('article.r1');
        // var r2 = $('article.r2');
        // var r3 = $('article.r3');
        // var r4 = $('article.r4');
        // var r5 = $('article.r5');
        // var rcommercial = $('article.rcommercial');
        // var rgarage = $('article.rgarage');
        //
        // $('.r0-container>.swiper-container>.swiper-wrapper').append(r0);
        // sliderArgs();
        //$('.r0-container>.swiper-container>.swiper-wrapper').sliderArgs();
        //$('.r0-container').slick(sliderArgs);

        //r1.clone().appendTo('.r1-container');
        //$('.r1-container').slick(sliderArgs);
        //r2.clone().appendTo('.r2-container');
        //$('.r2-container').slick(sliderArgs);
        //r3.clone().appendTo('.r3-container');
        //$('.r3-container').slick(sliderArgs);
        //r4.clone().appendTo('.r4-container');
        //$('.r4-container').slick(sliderArgs);
        //r5.clone().appendTo('.r5-container');
        //$('.r5-container').slick(sliderArgs);
        //rcommercial.clone().appendTo('.rcommercial-container');
        //$('.rcommercial-container').slick(sliderArgs);
        //rgarage.clone().appendTo('.rgarage-container');
        //$('.rgarage-container').slick(sliderArgs);


        // $('.filter-button').on('click', function(){
        //var target = $(this).attr('data-filter');
        //var content = $('article' + target);
        //console.log('target:  ', target);
        //console.log('content:  ', content);
        //$(target + '-container').clone(content);
        //$(target + '-container').not('.slick-initialized').slick(sliderArgs);
        // $('.r1-container>.swiper-container>.swiper-wrapper').append(r1);
        // $('.r2-container>.swiper-container>.swiper-wrapper').append(r2);
        // $('.r3-container>.swiper-container>.swiper-wrapper').append(r3);
        // $('.r4-container>.swiper-container>.swiper-wrapper').append(r4);
        // $('.r5-container>.swiper-container>.swiper-wrapper').append(r5);
        // $('.rcommercial-container>.swiper-container>.swiper-wrapper').append(rcommercial);
        // $('.rgarage-container>.swiper-container>.swiper-wrapper').append(rgarage);
        //$('.slick-prev').append();
        // sliderArgs();
        // });
        // $('.filter-button-all').on('click', function(){
        //     $('.r0-container>.swiper-container>.swiper-wrapper').append(r0);
        //     sliderArgs();
        //$('.r0-container').slick(sliderArgs);
        // });
        // });

        // if ($('.build-progress-wrap .mix').length > 0) {
        //     var mixer2 = mixitup('.build-progress-wrap > .build-content', {
        //         animation: {
        //             duration: 300
        //         },
        //         controls: {
        //             scope: 'local'
        //         },
        //         selectors: {
        //             control: '.controls button'
        //         },
        //         load: {
        //             filter: '.item0'
        //         }
        //     });
        // }


        $(".owl-buildings").owlCarousel({
          items: 1,
          dots: false,
          nav: true,
          navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>']
        });

        var owl = $(".owl-build-progress").owlCarousel({
          items: 1,
          dots: false,
          nav: true,
          navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>']
        });


        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
          console.log(e.target);

          var id = $(e.relatedTarget).attr('href');
          console.log(id);
        });


        $('.popup-gallery').each(function () {
          $(this).magnificPopup({
            delegate: 'a', // child items selector, by clicking on it popup will open
            type: 'image',
            gallery: {
              enabled: true
            }
          });
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');

    }
  };

  // Load Events
  jQuery(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
